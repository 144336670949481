<template>
  <div class="relative">
    <img
      src="https://images.pexels.com/photos/3747463/pexels-photo-3747463.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
      class="absolute inset-0 object-cover w-full h-full" alt=""/>

    <div class="relative relative bg-gray-900 bg-opacity-90">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="mx-auto">
          <div class="flex flex-col mb-16 sm:text-center sm:mb-0">
            <div class="max-w-xl mb-12 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span class="relative inline-block">
                <svg viewBox="0 0 52 24" fill="currentColor"
                     class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="700c93bf-0068-4e32-aafe-ef5b6a647708" x="0" y="0" width=".135" height=".30">
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <!-- <rect fill="url(#700c93bf-0068-4e32-aafe-ef5b6a647708)" width="52" height="24"></rect> -->
                </svg>
                <span class="relative">{{ title }}</span>
              </span>
                <!-- quick, brown fox jumps over a lazy dog -->
              </h2>
              <p class="text-base text-justify text-indigo-100 md:text-lg">
                {{ content }}
              </p>
            </div>
            <div>

              <div class="relative px-4 sm:px-0 mx-auto">
                <div class="absolute inset-0  h-1/2"></div>
                <div
                  class="relative grid overflow-hidden  divide-y rounded shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm sm:grid-cols-3 lg:max-w-screen-md mx-auto">

                  <div v-for="(address, index) in homeData.addresses" :key="index"
                       class="inline-block p-8 text-center mb-2 bg-white" style="margin:5px">
                    <div class="flex items-center justify-center w-12 h-12 mx-auto mb-4 rounded-full bg-indigo-50">
                      <svg class="w-10 h-10 text-gray-900" stroke="currentColor" viewBox="0 0 52 52">
                        <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                                 points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
                      </svg>
                    </div>
                    <p class="font-bold tracking-wide text-gray-800">{{ address.title }}</p>
                    <p class="tracking-wide text-gray-800" v-html="getHtmlContent(address.content)"></p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Adresse',
      content: "",
    }
  },
  props: {
    homeData: {
      type: Object,
    }
  },
  methods: {
    getHtmlContent(content) {
      return (content || "").replace("\n", "<br/>")
    }
  }
}
</script>
