<template>
  <div id="AboutUs" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
      <div>
        <!-- <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
          Brand new
        </p> -->
      </div>
      <h2
        class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        <span class="relative inline-block">

          <span class="relative">{{ title }}</span>
        </span>
        <!-- {{title}} -->
      </h2>
      <p class="text-base text-justify text-gray-700 md:text-lg">
         {{ homeData.organisation_name }} ist führend in der Telekommunikationsbranche und hat sich als innovativer Marktführer etabliert. Mit engen Beziehungen zu Tier-One-Carriern und deutschen Telekommunikationsunternehmen können wir außergewöhnliche Qualität und Service bieten. Mit einem unübertroffenen Portfolio an Telekommunikationsdiensten setzt  {{ homeData.organisation_name }} seit vielen Jahren sein Erbe an Führung und Innovation fort. Wir haben uns den Ruf erarbeitet, unseren Kunden und ihren Bedürfnissen einen qualitativ hochwertigen Service zu bieten. Unser Engagement für erstklassigen Service hat höchste Priorität.
      </p>
    </div>


    <div class="px-4 py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-12">
      <div class="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
        <div v-for="(point, index) in points" :key="index" class="p-8 border-b sm:border-r"
             v-bind:class="(index+1)%3 == 0 ? 'lg:border-r-0': ''">
          <div class="max-w-md text-center">
            <div
              class="flex items-center justify-center w-24 h-24 mx-auto mb-4 rounded-full bg-indigo-50 bg-opacity-50 sm:w-24 sm:h-24">

              <img v-if="point.svg!=''" class="w-12 h-12 sm:w-16 sm:h-16" :src='require(`@/assets/svgs/${point.svg}.svg`)'/>
              <svg v-else class="w-24 h-24 text-deep-purple-accent-400 sm:w-24 sm:h-24" stroke="currentColor"
                   viewBox="0 0 52 52">
                <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none"
                         points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
              </svg>
            </div>
            <!-- <h6 class="mb-2 font-semibold leading-5">The quick fox</h6> -->
            <p class="mb-3 text-center text-gray-900 md:text-lg font-semibold">
              {{ point.title }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <!-- <p>
          {{footer}}
      </p> -->
      <!-- <a
        href="/"
        class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
      >
        Learn more
      </a> -->
    </div>


  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Über uns",
      content: "",
      points: [
        {title: "Wettbewerbsfähige Preise.", svg: "Euro"},
        {title: "Größter Guthabenkartenanbieter in ganz Deutschland.", svg: "percentage"},
        {title: "Erweitertes System mit transparenter Abrechnung, keine Überraschungen zum Monatsende.", svg: "magnifying-glass"},
        {title: "Mit dem integrierten Live-Support-Chat werden sie schnell und einfach betreut.", svg: "support_chat"},
        {
          title: "Einfach wie 1,2,3! 1.Kaufen 2.Drucken 3.Aufladen",
          svg: ''
        },
        {
          title: "Barrierefreiheit. Unser Telefonkartendienst ist von praktisch jedem privaten Telefon mit Tonwahl in Deutschland aus leicht zu erreichen.",
          svg: "accessibility"
        },
      ],
    }
  },

  props: {
    homeData: {
      type: Object,
    }
  }
}
</script>
