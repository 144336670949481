<template>
  <div class="relative">
<!--    <img-->
<!--      src="https://images.pexels.com/photos/3747463/pexels-photo-3747463.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"-->
<!--      class="absolute inset-0 object-cover w-full h-full" alt=""/>-->

    <div class="relative bg-gray-200 bg-opacity-90">
      <div class="absolute inset-x-0 bottom-0">
        <svg viewBox="0 0 224 12" fill="#f8f8f8" class="w-full -mb-1 text-white" preserveAspectRatio="none">
          <path
            d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
        </svg>
      </div>
      <div class="px-4 py-36 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
        <div class="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center">
          <h2 class="mb-6 font-sans text-3xl font-bold tracking-tight text-black sm:text-4xl sm:leading-none">
            {{ homeData.organisation_name }}
          </h2>
<!--          <p class="mb-6 text-base text-indigo-100 md:text-lg">-->
<!--            {{ content }}-->
<!--          </p>-->
          <img class="relative max-w-2xl sm:mx-auto sm:max-w-xl md:max-w-2xl sm:text-center"
               :src="homeData.login_page_logo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    homeData: {
      type: Object,
    }
  }
}
</script>
