<template>
  <div id="Partners" class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
      <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        <span class="relative inline-block">

          <span class="relative">{{title}}</span>
        </span>
      </h2>
    </div>
    <div class="grid gap-5 sm:grid-cols-6 lg:grid-cols-8">
      <div v-for="(partner, index) in partners" :key="index">
        <div class="relative overflow-hidden transition duration-300 bg-white transform rounded justify-center shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
          <img class=" w-full h-36 w-36 md:h-36 md:w-36 xl:h-36 xl:w-36 justify-center mx-auto" :src="require(`@/assets/images/partners/${partner.image}`)" alt="Person" />

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            title: "Unsere Partner",
            partners: [
                { image: "1.jpg",},
                { image: "2.jpg",},
                { image: "3.jpg",},
                { image: "4.png",},
                { image: "7.jpg",},
                { image: "8.jpg",},
                { image: "9.jpg",},
                { image: "10.jpg",},
                { image: "11.jpg",},
                { image: "12.jpg",},
                { image: "13.jpg",},
                { image: "14.jpg",},
            ]
        }
    }
}
</script>
