<template>
  <div id="WhatWeDo" class="relative">
    <img
      src="https://images.pexels.com/photos/3747463/pexels-photo-3747463.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
      class="absolute inset-0 object-cover w-full h-full" alt=""/>

    <div class="relative relative bg-gray-900 bg-opacity-90">
      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div class="flex flex-col mb-16 sm:text-center sm:mb-0">
            <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
              <span class="relative inline-block">
                <svg viewBox="0 0 52 24" fill="currentColor"
                     class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-deep-purple-accent-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
                  <defs>
                    <pattern id="700c93bf-0068-4e32-aafe-ef5b6a647708" x="0" y="0" width=".135"
                             height=".30">
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                </svg>
                <span class="relative">{{ title }}</span>
              </span>
              </h2>

              <p class="text-base text-indigo-100 md:text-lg">
                {{ content }}
              </p>
            </div>
            <div>

              <div class="grid gap-8 row-gap-8 lg:grid-cols-3">
                <div v-for="doData in dosData" class="sm:text-center" :key="doData.title">

                  <a class="cursor-pointer" @click="showModal(doData.logo, doData)" v-if="isPjtelesoftgmbh(doData)">
                    <div
                      class="flex items-center justify-center w-24 h-24 mb-4 text-5xl font-extrabold rounded-full bg-gray-800 bg-opacity-50 sm:mx-auto">
                      <img class=" h-10" :src='require(`@/assets/svgs/${doData.svg}`)'/>

                    </div>
                    <h4 class="mb-2 font-semibold leading-10 text-gray-100">{{ doData.title }}</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed z-400001 inset-0 overflow-y-auto" v-if="showWhatWeDoModal"
         @click="showWhatWeDoModal = false">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h2 class="text-bold text-3xl leading-6 font-medium text-gray-900 text-center"
                    id="modal-headline">
                  {{ cTitle }}
                </h2>
                <div class="mt-4 mb-4">
                  <div v-if="type === 1" id="content">
                    <section class="text-lg leading-loose text-black">
                      <p>If you are a retailer store owner, distributor or you have your own online
                        portal for selling
                        E-Pins, our Wholesale Program is exactly for you. We are one of the largest
                        distributors of E
                        Pins in Germany for cash cards which are widely topped up in German
                        market.<br>
                        We cover almost all German carriers that include Telekom, Vodafone, Eplus,
                        O2, Turkstar,
                        Turkcell, Lyca mobile, Lebara mobile and many others.<br> All epins are
                        available at wholesale
                        pricing in PIN format. PINs will be delivered to you in an different
                        formats, you can chose
                        yourself if you need Excel or text or pdf or you want to print any pin
                        directly. Contact a sales
                        representative today!<br>
                        On each E-Pin, there is a special number for 24x7 support. you can call that
                        connects you with
                        the carrier company's customer service department. This number varies from
                        voucher to voucher,
                        the customer service number that corresponds to your voucher is wellknown
                        and you can find these
                        support numbers on each carrier website as well. <br>We are number one
                        company not only in
                        e-pins sale but also in customer support. We have many happy customers over
                        the past many years.
                        If you have a problem that can't be resolved by the carrier company's
                        customer service, just
                        contact us within 2 days, and we will definitely help you resolving the
                        issue.</p>

                    </section>
                  </div>
                  <div class="" v-if="type === 2">
                    <section class="text-lg leading-loose text-black">
                      <p>Do you operate a store, grocery store, or any other business that receives
                        many customers?<br/>
                        Then add another stream of revenue to your business by installing
                        {{ homeData.organisation_name }} Point of Sale
                        system, which offers these benefits:</p>
                      <br/>
                      <ul class="list-disc list-outside ml-4 text-gray-600">
                        <li>Buy at wholesale prices. You can buy our vouchers at discounted prices
                          to increase your
                          profits.
                        </li>
                        <li>Sell at retail or below. You can sell vouchers at retail prices and
                          enjoy the profit of a
                          healthy markup.
                        </li>
                        <li>No inventory to steal. Keeping an inventory of vouchers offers thieves
                          an easy opportunity
                          to steal because printed vouchers cards are small and easy to conceal.
                          When you sell using {{ homeData.organisation_name }}
                          POS, you only print vouchers when customer wants to buy from you, so
                          there's no risk
                          of vouchers theft!
                        </li>
                        <li>Great Customer Service. Unlike other companies that disappear after the
                          sale, {{ homeData.organisation_name }}
                          track record in Germany is self explanatory, {{
                            homeData.organisation_name
                          }} continues to support you after the
                          sale with great customer service. If there's a problem, we'll fix it. See
                          our policies, most
                          notably that we will repair or replace any malfunctioning voucher that you
                          have purchased from
                          {{ homeData.organisation_name }} within the last 7 days.
                        </li>
                      </ul>
                      <br/>
                      <p>{{ homeData.organisation_name }} provides low rates, time-saving Top-up
                        vouchers a one stop convenient way for
                        almost all carriers of Germany! Travelers, students, businesspeople, and
                        anyone with friends or
                        family will enjoy saving.
                        We sell top vouchers to whole sellers and we are market leaders with respect
                        to Lycamobile Top
                        up Vouchers. Each card displays the rechargeable amount such as 5 €, 10 € ,
                        15 € etc. Rest of
                        the information allows you, as a smart consumer, to topup the voucher and
                        check your balance
                        after sucesfull recharge.
                        After successfully recharging you can now make calls to your friends, family
                        and colleagues,
                        these vouchers are totally separate from your regular phone bill.</p>
                    </section>
                  </div>
                  <div class="" v-if="type === 3">
                    <section class="text-lg leading-loose text-black">
                      <p>We are whole-sellers of Mobile Phones in all ranges starting from a the
                        basic phone to high end
                        phones all in one place. We are committed to long-term business
                        relationships and acting
                        dynamically. Only satisfied customers will bind themselves long-term. The
                        extensive experience
                        of our team account for continuous satisfaction. <br>Through good service,
                        {{ homeData.organisation_name }} has
                        earned the trust of partners, customers and suppliers all over the
                        world.<br>
                        {{ homeData.organisation_name }} helps you find the right product at the
                        right price when you’re in Mobile phones
                        business. We have an extensive collection with latest technology in
                        smart-phones. We offer the
                        best prices!!!!!</p>
                      <p>Having over many years experience in the cellular phone industry,
                        {{ homeData.organisation_name }} prides itself on
                        its solid reputation in the industry, offering top quality phones while
                        providing dependable and
                        reliable customer service. Contact us for latest mobile phone purchase,
                        mobile phone software
                        customization and logistics.</p>

                    </section>
                  </div>
                  <div class="" v-if="type === 4">
                    <section class="text-lg leading-loose text-black">
                      <p>We are one of the major distributors of Sim cards of all varieties in
                        German market. We are one
                        of the best companies to deal with if you decide to buy and sell wholesale
                        Sim cards. We don’t
                        charge a set-up fee, so all of your original investment is directly in the
                        product. We also
                        provide free support to customers who are distributing our wholesale Sim
                        cards, so you have
                        built-in backup at all times.</p>
                      <p>Wholesale Sim Cards are an excellent business opportunity for just about
                        anyone who is
                        interested; there is no experience required to become a distributor, and
                        once you sign a
                        contract with us, your Sim card business is off and running. You will have a
                        global market,
                        since our cards work from anywhere in Germany and some are working from
                        whole Europe, so sell
                        them in any region you choose. All you need to be a successful wholesale Sim
                        card distributor is
                        the desire to succeed!</p>
                    </section>
                  </div>

                  <div class="" v-if="type === 5">

                    <section class="text-lg leading-loose text-black">
                      <p>Regardless of your IT needs, {{ homeData.organisation_name }} can provide
                        the IT Services you need. We provide
                        highly competent IT professionals to deliver high value, cost effective IT
                        competence where it
                        is needed the most. We bring a unique business perspective to technology. We
                        look at what our
                        clients are trying to accomplish and focus on their business needs
                        {{ homeData.organisation_name }} provides web development services such as
                        graphic design, static site development,
                        CMS (Content Management System) development, eCommerce development,
                        integration with 3rd party
                        systems, and SEO refinement.<br>
                        We provide custom application development as well to clients requiring
                        unique software
                        solutions. We provide the design, development, installation, and support of
                        completely custom
                        systems. We also can provide modifications to existing software packages as
                        well.
                      </p>
                    </section>
                  </div>
                  <div class="" v-if="type === 6">
                    <section class="text-lg leading-loose text-black">
                      <p>Partner mit ein internationales Unternehmen, das sich auf
                        Engineering-Lösungen und -Dienstleistungen sowie emissionsfreie
                        Elektromobilitätsprodukte spezialisiert hat. Wir bieten innovative Produkte
                        und technologische Entwicklungen im Bereich Automotive und Mobility. Mehr
                        info <a href="https://evo-syn.com/" target="_blank">hier</a>
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      showWhatWeDoModal: false,
      title: 'Was wir tun',
      content: "",
      cTitle: '',
      type: null,
      dosData: [
        {
          logo: 1,
          svg: "printer.svg",
          title: "E Voucher",
        },
        {
          logo: 2,
          svg: "notes.svg",
          title: "Top-Up Voucher",
        },
        {
          logo: 3,
          svg: "iPhone.svg",
          title: "Mobile Phones"
        },
        {
          logo: 4,
          svg: "sim_card.svg",
          title: "SIM Cards"
        },
        {
          logo: 5,
          svg: "MoonBook.svg",
          title: "IT Services"
        },
        {
          logo: 6,
          svg: "engineering.png",
          title: "Engineering Services"
        },
      ]
    }
  },
  methods: {
    showModal(type, data) {
      this.type = type;
      this.cTitle = data.title;
      this.showWhatWeDoModal = true;
    },
    isPjtelesoftgmbh(doData) {
      if(doData.logo != 6) {
        return true;
      } else {
        try {
          return this.homeData.organisation_name == "PJ Telesoft GmbH";
        } catch (e) {
          return false;
        }
      }
    }
  },
  props: {
    homeData: {
      type: Object,
    }
  }
}
</script>
