<template>
  <div class=" pb-6 home-page" v-if="homeData">
    <NavBar :home-data="homeData"/>
    <TheHeader :home-data="homeData"/>
    <AboutUs :home-data="homeData"/>
    <WhatWeDo :home-data="homeData"/>
    <ContactUs :home-data="homeData" v-on:success="toggleContact"/>
    <ContactData :home-data="homeData"/>
    <Partners/>
    <!--    <TheFooter/>-->

    <div class="fixed z-400001 inset-0 overflow-y-auto" v-if="showContactSuccess">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">

                <svg id="Capa_1"
                     class="text-success"
                     xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16" height="16"
                     stroke="currentColor"
                     viewBox="0 0 352.62 352.62" style="enable-background:new 0 0 352.62 352.62;" xml:space="preserve">
                <g>
                  <path
                    d="M337.222,22.952c-15.912-8.568-33.66,7.956-44.064,17.748c-23.867,23.256-44.063,50.184-66.708,74.664   c-25.092,26.928-48.348,53.856-74.052,80.173c-14.688,14.688-30.6,30.6-40.392,48.96c-22.032-21.421-41.004-44.677-65.484-63.648   c-17.748-13.464-47.124-23.256-46.512,9.18c1.224,42.229,38.556,87.517,66.096,116.28c11.628,12.24,26.928,25.092,44.676,25.704   c21.42,1.224,43.452-24.48,56.304-38.556c22.645-24.48,41.005-52.021,61.812-77.112c26.928-33.048,54.468-65.485,80.784-99.145   C326.206,96.392,378.226,44.983,337.222,22.952z M26.937,187.581c-0.612,0-1.224,0-2.448,0.611   c-2.448-0.611-4.284-1.224-6.732-2.448l0,0C19.593,184.52,22.653,185.132,26.937,187.581z"/>
                </g>

                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Success
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    We received your contact request. We will get back to you soon.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button type="button"
                    @click="showContactSuccess = false"
                    class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import NavBar from "./components/NavBar.vue";
import AboutUs from "./components/AboutUs.vue";
import TheHeader from "./components/TheHeader.vue";
import WhatWeDo from "./components/WhatWeDo.vue";
import ContactUs from "./components/ContactUs.vue";
import ContactData from "./components/ContactData.vue";
import Partners from "./components/Partners.vue";

export default {
  name: 'OrgHomePage',
  data() {
    return {
      homeData: null,
      whatWeDoModal: true,
      showContactSuccess: false,
    }
  },
  components: {Partners, ContactData, ContactUs, WhatWeDo, TheHeader, AboutUs, NavBar,},
  methods: {
    toggleContact() {
      this.showContactSuccess = !this.showContactSuccess;
    },
    checkOpenPage() {
      if (localStorage.getItem('accessToken') && localStorage.getItem('userInfo')) {
        this.$router.push('/dashboard')
      } else {
        this.$router.push('/login')
      }
    },
    checkOrganisation() {
      this.$store.dispatch('auth/fetchOrgDetails')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {
            this.loadData()
          } else {
            // this.$router.push({ path: '/pages/error-404' })
          }
        })
        .catch((error) => {
          this.$router.push({path: '/pages/error-404'})
        })
    },
    loadData() {
      const data = localStorage.getItem('home')
      this.homeData = JSON.parse(data)
      this.pages = this.homeData.pages
      try {
        this.currentPage = this.homeData.pages[0]
      } catch (e) {
      }
    },
  },
  mounted() {
    this.$store.dispatch('updateTheme', 'light')
    this.$vs.theme({ primary: '#00a1f1' })

    const data = localStorage.getItem('home')

    if (data) {
      this.loadData()
    } else {
      this.checkOrganisation()
    }
  },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Sample `apply` at-rules with Tailwind CSS
.container {
@apply min-h-screen flex justify-center items-center text-center mx-auto;
}
*/
.container {
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: 'Quicksand',
  'Source Sans Pro',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  sans-serif;
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}

.z-400001 {
  z-index: 400001 !important;
}
</style>
