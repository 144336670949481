<template>
  <div class="relative" id="ContactUs">
    <!-- <img src="https://images.pexels.com/photos/3747463/pexels-photo-3747463.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260" class="absolute inset-0 object-cover w-full h-full" alt="" /> -->
    <div class="relative">
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div class="flex flex-col items-center justify-between xl:flex-row">
          <div class="w-full max-w-xl mb-12 xl:mb-0 xl:pr-16 xl:w-7/12">


            <h2
              class="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              {{ title }}
              <!-- <br class="hidden md:block" />
              jumps over a <span class="text-teal-accent-400">lazy dog</span> -->
            </h2>
            <p class="max-w-xl text-justify mb-4 text-base text-gray-700 md:text-lg">
              Wir haben uns den Ruf erarbeitet, unseren Kunden und ihren Bedürfnissen einen qualitativ hochwertigen Service zu bieten. Unser Engagement für erstklassigen Service ist eine unserer höchsten Prioritäten. Um Ihr Problem so sorgfältig wie möglich anzugehen, schreiben Sie bitte deutlich. Die meisten Fragen werden am selben Tag beantwortet. Wenn Sie weitere Hilfe benötigen, teilen Sie uns dies bitte per E-Mail oder Telefon mit. Ein entsprechender Vertreter wird Sie per E-Mail kontaktieren.
            </p>
            <!-- <a href="/" aria-label="" class="inline-flex items-center font-semibold tracking-wider transition-colors duration-200 text-teal-accent-400 hover:text-teal-accent-700">
              Learn more
              <svg class="inline-block w-3 ml-2" fill="currentColor" viewBox="0 0 12 12">
                <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path>
              </svg>
            </a> -->
          </div>
          <div class="w-full max-w-xl xl:px-8 xl:w-5/12">
            <div class="bg-white rounded shadow-2xl p-7 sm:p-10">
              <!-- <h3 class="mb-4 text-xl font-semibold sm:text-center sm:mb-6 sm:text-2xl">
                {{title}}
              </h3> -->
              <form class="text-left"
                    @submit="submitForm">
                <div class="mb-1 sm:mb-2">
                  <label for="yourName" class="inline-block mb-1 font-medium">Vorname und Nachname<span class="text-danger">*</span></label>
                  <input
                    placeholder="John"
                    required=""
                    type="text"
                    v-model="name"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="yourName"
                    name="yourName"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="email" class="inline-block mb-1 font-medium">Ihre Email id<span class="text-danger">*</span></label>
                  <input
                    placeholder="example@gmail.com"
                    required=""
                    type="email"
                    v-model="email"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                  />
                </div>
                <div class="mb-1 sm:mb-2">
                  <label for="phoneNumber" class="inline-block mb-1 font-medium">Ihre Handy Nr<span class="text-danger">*</span></label>
                  <input
                    placeholder="+491234567890"
                    required=""
                    type="text"
                    v-model="phone"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    name="phoneNumber"
                  />
                </div>

                <div class="mb-1 sm:mb-2">
                  <label for="message" class="inline-block mb-1 font-medium">Ihre Nachricht<span class="text-danger">*</span></label>
                  <input
                    placeholder="Nachricht eingeben"
                    required=""
                    type="text"
                    v-model="message"
                    class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none focus:border-gray-800 focus:outline-none focus:shadow-outline"
                    id="message"
                    name="message"
                  />
                </div>
                <div class="mt-4 mb-2 sm:mb-4">
                  <button
                    type="submit"
                    :disabled="!validForm"
                    class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                    :class="!validForm ? 'bg-gray-600' : 'bg-gray-900  hover:bg-gray-800'"
                  >
                    Nachricht senden
                  </button>
                </div>
                <p v-if="errorMessage" class="text-xs text-red-500 sm:text-sm">
                  {{ errorMessage }}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  data() {
    return {
      email: '',
      phone: '',
      name: '',
      message: '',
      errorMessage: '',
      title: "Kontaktiere uns!",
    }
  },
  computed: {
    validForm() {
      return this.email !== '' && this.phone !== '' && this.name !== '' && this.message !== '' && this.validEmail(this.email) && this.validPhone(this.phone)
    }
  },
  props: {
    homeData: {
      type: Object,
    }
  },
  methods: {
    validEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone(phone) {
      const re = /^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm;
      return re.test(phone);
    },
    submitForm(e) {
      this.errorMessage = ''
      e.preventDefault()
      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('name', this.name)
      formData.append('mobile', this.phone)
      formData.append('message', this.message)
      axios.post('/add_contact_us/', formData).then((data) => {
        this.$emit('success')
        this.email = ''
        this.name = ''
        this.mobile = ''
        this.message = ''
      }).catch(err => {
        this.errorMessage = 'Failed to submit request'
      })
    },
  }
}
</script>

